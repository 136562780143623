<template>
  <picture
    :loading="loading"
    :data-not-lazy="loading !== 'lazy'"
  >
    <source
      v-if="srcsetStack.webp"
      type="image/webp"
      :srcset="srcsetStack.webp"
      :sizes="sizes"
    >
    <source
      v-if="srcsetStack.original"
      :type="data.mime"
      :srcset="srcsetStack.original"
      :sizes="sizes"
    >
    <img
      :src="`${config.public.mediaBase}${data.url}`"
      :sizes="sizes"
      :alt="altText"
      :width="ratios ? data.width : ''"
      :height="ratios ? data.height : ''"
      :loading="loading"
      @load="emit('loaded', $event)"
    >
  </picture>
</template>

<script setup>
//  <img :src="`${config.public.mediaBase}${data.url}`" :sizes="sizes" :alt="data.alternativeText" :width="data.width" :height="data.height" :loading="loading" @load="emit('loaded', $event)"></img> 

const config = useRuntimeConfig()
const emit = defineEmits(['loaded'])
const props = defineProps({
	data: {
		type: Object,
		required: true
	},
	sizes: {
		type: String,
		default: '',
		required: false
	},
	loading: {
		type: String,
		required: false,
		default: 'lazy' // eager, lazy
	},
	ratios: {
		type: Boolean,
		required: false,
		default: true
	}
})

const altText = computed(() => {
	const data = props.data || props.data.data
	return data.alt || data.altText || data.alternativeText || data.name || data.title || data.caption || data.description || data.url
})

const srcsetStack = computed(() => {
	const data = props.data || props.data.data

	if (!data.formats) { return null }

	const srcSet = { original: Object.keys(data.formats) }
	srcSet.original = srcSet.original.map(size => `${config.public.mediaBase}${data.formats[size].url} ${data.formats[size].width}w`).join(', ')

	// if (srcSet.original.includes('.jpg') || srcSet.original.includes('.png')) {
	// 	srcSet.webp = srcSet.original.replace(/.png|.jpg|.jpeg/g, '.webp')
	// }

	if (srcSet.original.includes('.jpg') || srcSet.original.includes('.png')) {
		srcSet.webp = srcSet.original.replace(/uploads/g, 'uploads/f_webp')
	}

	return srcSet
})
</script>
